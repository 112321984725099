import { render, staticRenderFns } from "./WeekDays.vue?vue&type=template&id=34f8dbed&scoped=true&"
import script from "./WeekDays.vue?vue&type=script&lang=js&"
export * from "./WeekDays.vue?vue&type=script&lang=js&"
import style0 from "./WeekDays.vue?vue&type=style&index=0&id=34f8dbed&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34f8dbed",
  null
  
)

component.options.__file = "WeekDays.vue"
export default component.exports